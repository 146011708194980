import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newUserDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class UserDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      userDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let userDetail = this.state.userDetail;
    if (userDetail.display_name.length > 1) {
      createUpdateDoc('users', userDetail.key, userDetail, this.props.isNew);
    }

    if (userDetail.deleted) {
      recycleDoc('usres', userDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ userDetail: newUserDetail });
    } else {
      this.setState({ userDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let userDetail = { ...this.state.userDetail };

    if (target.type === 'checkbox') {
      userDetail[name] = target.checked;
    } else {
      userDetail[name] = value;
    }
    this.setState({ userDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let userDetail = { ...this.state.userDetail };
            userDetail['deleted'] = true;
            userDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ userDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleCheck = (selectedItemKey) => {
    let userDetail = this.state.userDetail;
    let newPermissionLevels = [];
    const isChecked = userDetail.permission_levels.includes(selectedItemKey);

    if (isChecked) {
      newPermissionLevels = userDetail.permission_levels.filter(
        (contact) => contact !== selectedItemKey
      );
    } else {
      userDetail.permission_levels.push(selectedItemKey);
      newPermissionLevels = userDetail.permission_levels;
    }

    userDetail['permission_levels'] = newPermissionLevels;
    this.setState({ userDetail });
  };

  render() {
    const userDetail = this.state.userDetail;
    const permissionLevels = this.props.appData['permission_levels'];

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='display_name'>
                    Display Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Display Name'
                    name='display_name'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={userDetail.display_name || ''}></input>
                </div>
              </div>
              <div className='d-flex'>
                <div className='form-group w-25'>
                  <label className='form_label' htmlFor='mobile_phone'>
                    Phone Number
                  </label>
                  <input
                    className='form-control'
                    placeholder='Phone Number'
                    name='mobile_phone'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={userDetail.mobile_phone || ''}></input>
                </div>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='email_address'>
                  Email Address
                </label>
                <input
                  className='form-control'
                  placeholder='Email Address'
                  name='email_address'
                  type='text'
                  onChange={this.handleItemDetailFormChange}
                  value={userDetail.email_address || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={userDetail.notes || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='permission_levels'>
                  Permissions
                </label>

                {permissionLevels.map((item, key) => {
                  let isChecked = false;
                  if (this.state.userDetail.permission_levels) {
                    isChecked = this.state.userDetail.permission_levels.includes(
                      item.key
                    );
                  }

                  return (
                    <div key={key}>
                      <input
                        key={key}
                        name='permission_level'
                        checked={isChecked}
                        type='checkbox'
                        onChange={() => this.handleCheck(item.key)}
                        value={item.key}
                      />
                      &nbsp;
                      <label className='form_label' htmlFor='permission_level'>
                        {item.permission_name}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='access_allowed'>
                  Access Allowed?
                </label>
                <select
                  className='form-control'
                  placeholder='Access Allowed?'
                  name='access_allowed'
                  onChange={this.handleItemDetailFormChange}
                  value={userDetail.access_allowed || '0'}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={userDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNew ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Contact
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
