import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newAttendanceDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class AttendanceDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      attendanceDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let attendanceDetail = this.state.attendanceDetail;

    if (attendanceDetail.event_key && attendanceDetail.event_key.length > 1) {
      createUpdateDoc(
        'attendance',
        attendanceDetail.key,
        attendanceDetail,
        this.props.isNew
      );
    }

    if (attendanceDetail.deleted) {
      recycleDoc('attendance', attendanceDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ attendanceDetail: newAttendanceDetail });
    } else {
      this.setState({ attendanceDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let attendanceDetail = { ...this.state.attendanceDetail };

    if (target.type === 'checkbox') {
      attendanceDetail[name] = target.checked;
    } else {
      attendanceDetail[name] = value;
    }
    this.setState({ attendanceDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this attendance register?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let attendanceDetail = { ...this.state.attendanceDetail };
            attendanceDetail['deleted'] = true;
            attendanceDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ attendanceDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleCheck = (selectedItemKey) => {
    let attendanceDetail = this.state.attendanceDetail;
    let newContactsAttended = [];
    const isChecked = attendanceDetail.contacts_attended.includes(
      selectedItemKey
    );

    if (isChecked) {
      newContactsAttended = attendanceDetail.contacts_attended.filter(
        (contact) => contact !== selectedItemKey
      );
    } else {
      attendanceDetail.contacts_attended.push(selectedItemKey);
      newContactsAttended = attendanceDetail.contacts_attended;
    }

    attendanceDetail['contacts_attended'] = newContactsAttended;
    this.setState({ attendanceDetail });
  };

  render() {
    const attendanceDetail = this.state.attendanceDetail;
    const eventsList = this.props.eventsList;
    const contactsList = this.props.contactsList;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group w-50'>
                {' '}
                <label className='form_label' htmlFor='event_key'>
                  Event Name
                </label>
                <select
                  className='form-control'
                  name='event_key'
                  onChange={this.handleItemDetailFormChange}
                  value={
                    attendanceDetail.event_key
                      ? attendanceDetail.event_key
                      : 'none'
                  }>
                  {eventsList.map((item, key) => {
                    return (
                      <option key={key} value={item.key}>
                        {item.start_date} {item.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='d-flex'>
                <div className='form-group w-15'>
                  <label className='form_label' htmlFor='total_adults'>
                    Total Adults
                  </label>
                  <input
                    className='form-control'
                    placeholder='Total Adults'
                    name='total_adults'
                    type='number'
                    onChange={this.handleItemDetailFormChange}
                    value={attendanceDetail.total_adults || '0'}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='total_children'>
                    Total Children
                  </label>
                  <input
                    className='form-control'
                    placeholder='Total Adults'
                    name='total_children'
                    type='number'
                    onChange={this.handleItemDetailFormChange}
                    value={attendanceDetail.total_children || '0'}></input>
                </div>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='contacts_attended'>
                  Contacts
                </label>

                {contactsList.map((item, key) => {
                  let isChecked = false;
                  if (this.state.attendanceDetail.contacts_attended) {
                    isChecked = this.state.attendanceDetail.contacts_attended.includes(
                      item.key
                    );
                  }

                  return (
                    <div key={key}>
                      <input
                        key={key}
                        name='contact_name'
                        checked={isChecked}
                        type='checkbox'
                        onChange={() => this.handleCheck(item.key)}
                        value={item.key}
                      />
                      &nbsp;
                      <label className='form_label' htmlFor='contact_name'>
                        {item.first_name} {item.last_name}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='new_people'>
                  Enter names and contact details of new people who attended
                </label>
                <textarea
                  className='form-control'
                  placeholder='New people'
                  name='new_people'
                  rows='3'
                  onChange={this.handleItemDetailFormChange}
                  value={attendanceDetail.new_people || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={attendanceDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={attendanceDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewContact ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Attendance Register
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
