import React, { Component } from 'react';
import Collapse from '@kunukn/react-collapse';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import {
  returnEventTasksColumnsDef,
  newEventTaskDetail,
} from '../../helpers/dataObjects.jsx';
import { db } from '../../services/firebase';

let unSubSnapshot = {};

class EventTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapseOpen: false,
      tasksAllocated: [],
      items: [],
      componentData: {},
      tasksList: this.props.tasksList,
      dataLoaded: false,
    };
  }

  async componentDidMount() {
    const tasksList = this.state.tasksList;
    const eventId = this.props.eventDetail.key;

    try {
      unSubSnapshot['event_tasks'] = db
        .collection('event_tasks')
        .where('event_id', '==', eventId)
        .onSnapshot((snapshot) => {
          let setStateData = [];
          let appTasksIds = [];
          let componentData = this.state.componentData;

          snapshot.forEach((doc) => {
            let snapshotData = [];
            snapshotData = doc.data();
            snapshotData['key'] = doc.id;
            setStateData.push(snapshotData);

            if (snapshotData['app_task_id'].length > 0) {
              appTasksIds.push(snapshotData['app_task_id']);
            }
          });

          const newTasksList = tasksList.filter((task) => {
            return !appTasksIds.includes(task.key);
          });

          componentData['event_tasks'] = setStateData;

          this.setState({
            componentData,
            dataLoaded: true,
            tasksList: newTasksList,
          });
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  handleCollapseOpenClose = () => {
    this.setState({ isCollapseOpen: !this.state.isCollapseOpen });
  };

  handleCheck = (selectedItemKey) => {
    console.log(selectedItemKey);
    console.log('state.tasksList:', this.state.tasksList);

    const tasksList = this.state.tasksList;

    const newTasksList = tasksList.filter(
      (task) => task.key !== selectedItemKey
    );

    const currentTask = tasksList.filter(
      (task) => task.key === selectedItemKey
    );

    let newEventTask = newEventTaskDetail;

    newEventTask['event_id'] = this.props.eventDetail.key;
    newEventTask['task_name'] = currentTask[0].task_name;
    newEventTask['app_task_id'] = selectedItemKey;

    db.collection('event_tasks').add(newEventTask);

    this.setState({ tasksList: newTasksList });
  };

  handleAddAppTask = (selectedItemKey) => {
    const tasksList = this.state.tasksList;

    const newTasksList = tasksList.filter(
      (task) => task.key !== selectedItemKey
    );

    const currentTask = tasksList.filter(
      (task) => task.key === selectedItemKey
    );

    let newEventTask = newEventTaskDetail;

    newEventTask['event_id'] = this.props.eventDetail.key;
    newEventTask['task_name'] = currentTask[0].task_name;
    newEventTask['app_task_id'] = selectedItemKey;

    db.collection('event_tasks').add(newEventTask);

    this.setState({ tasksList: newTasksList });
  };

  handleAddBlankTask = () => {
    let newEventTask = newEventTaskDetail;
    newEventTask['event_id'] = this.props.eventDetail.key;
    newEventTask['task_name'] = 'New task';
    db.collection('event_tasks').add(newEventTask);
  };

  render() {
    const dataLoaded = this.state.dataLoaded;
    const tasksList = this.state.tasksList;

    const componentData = this.state.componentData;

    let filteredItems = componentData['event_tasks']
      ? componentData['event_tasks']
      : [];

    const eventTaskTableDef = returnEventTasksColumnsDef(
      componentData,
      this.props.leadersList
    );
    const columns = eventTaskTableDef.columns;
    const defaultSorted = eventTaskTableDef.defaultSorted;

    return (
      <>
        {!dataLoaded ? (
          <div
            className='spinner-border text-success isDataLoadCompleted-indicator'
            role='status'>
            <span className='sr-only'>Loading... </span>
          </div>
        ) : (
          <div>
            Event Tasks component
            <div className='form-group'>
              <div
                className='btn btn-primary'
                onClick={this.handleCollapseOpenClose}>
                Show/Hide Tasks
              </div>
              <Collapse isOpen={this.state.isCollapseOpen}>
                {tasksList.map((item, key) => {
                  return (
                    <div className='w-100' key={key}>
                      <div
                        className='row btn btn-secondary'
                        onClick={() => this.handleAddAppTask(item.key)}>
                        <div className='col'>+ {item.task_name}</div>
                        <div className='col'></div>
                      </div>
                    </div>
                  );
                })}
              </Collapse>
              <hr />
              <div
                className='float-right btn btn-primary'
                onClick={this.handleAddBlankTask}>
                Add New Blank Task
              </div>
              <BootstrapTable
                noDataIndication='There are no tasks to display here at the moment'
                classes='auto-table-layout'
                keyField='key'
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    db.collection('event_tasks').doc(row.key).set(row);
                  },
                })}
                data={filteredItems}
                columns={columns}
                striped
                hover
                condensed
                bordered={false}
                defaultSorted={defaultSorted}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
export default EventTasks;
