import React, { Component } from 'react';
import dayjs from 'dayjs';

class DashboardSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  async componentWillUnmount() {}

  getEventNameFromKey = (eventKey) => {
    const eventsList = this.props.appData.events;
    const eventName = eventsList.filter((item) => item.key === eventKey);
    return (
      eventName[0].title +
      ' - ' +
      dayjs(eventName[0].start_date).format('D/M/YYYY')
    );
  };

  render() {
    let futureEvents = [];
    let myTasks = [];

    const eventsList = this.props.appData.events;
    const helpfulLinks = this.props.appData.links;
    const leadersList = this.props.appData.leaders;
    const eventTasks = this.props.appData.event_tasks;
    const currentUserID = this.props.currentUserDetails.uid;

    const myContacts = this.props.appData.contacts.filter(
      (contact) => contact.care_leader === currentUserID
    );

    if (eventsList && eventTasks) {
      const tempMyTasks = eventTasks.filter(
        (task) =>
          task.assigned_leader_id === currentUserID ||
          task.assigned_leader_id === ''
      );

      myTasks = tempMyTasks.map((task) => {
        return {
          ...task,
          event_name: this.getEventNameFromKey(task.event_id),
        };
      });
    }

    if (eventsList && eventsList.length > 0 && leadersList.length > 0) {
      futureEvents = eventsList.filter((item) =>
        dayjs(item.start_date).isAfter(dayjs())
      );

      if (futureEvents.length > 0) {
        const careLeader = leadersList.filter(
          (leader) => leader.key === futureEvents[0].care_leader
        );

        futureEvents[0]['care_leader_name'] =
          careLeader.length > 0
            ? careLeader[0].display_name
            : 'No care leader assigned';
      }
    }

    //nextEvent = eventsList.filter((event) => dayjs(event.date) > dayjs())

    return (
      <>
        <section className='bg-light py-4 my-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-lg-4'>
                <div className='card my-3'>
                  <div className='card-body'>
                    <h5 className='card-title'>Next Event:</h5>

                    {futureEvents && futureEvents.length > 0 ? (
                      <>
                        <p>
                          Date:{' '}
                          {dayjs(futureEvents[0].start_date).format('D/M/YYYY')}
                        </p>
                        <p>Location: {futureEvents[0].location}</p>
                        <p>Entry Fee: {futureEvents[0].entry_fee}</p>
                        <p>Leader: {futureEvents[0].care_leader_name}</p>
                      </>
                    ) : (
                      <p>No events scheduled.</p>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-6'>
                <div className='card my-3'>
                  <div className='card-body'>
                    <h5 className='card-title'>My Tasks</h5>
                    <table className='table table-sm'>
                      <tbody>
                        {myTasks && myTasks.length > 0 ? (
                          myTasks.map((task) => {
                            return (
                              <tr className='' key={task.key}>
                                <td>{task.task_name}</td>

                                <td> {task.event_name}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className=''>
                            <td>No tasks found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-4'>
                <div className='card my-3'>
                  <div className='card-body'>
                    <h5 className='card-title'>Leaders</h5>
                    <table className='table table-sm'>
                      <tbody>
                        {leadersList && leadersList.length > 0 ? (
                          leadersList.map((leaderDetail) => {
                            return (
                              <tr className='' key={leaderDetail.key}>
                                <td>{leaderDetail.display_name}</td>

                                <td>
                                  <a href={'tel:' + leaderDetail.mobile_phone}>
                                    {leaderDetail.mobile_phone}
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className=''>
                            <td>No leaders found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-4'>
                <div className='card my-3'>
                  <div className='card-body'>
                    <h5 className='card-title'>My Contacts</h5>

                    <table className='table table-sm'>
                      <tbody>
                        {myContacts && myContacts.length > 0 ? (
                          myContacts.map((contactDetail) => {
                            return (
                              <tr className='' key={contactDetail.key}>
                                <td>
                                  {contactDetail.first_name}&nbsp;
                                  {contactDetail.last_name}
                                </td>

                                <td> {contactDetail.phone_number}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className=''>
                            <td>No contacts found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-4'>
                <div className='card my-3'>
                  <div className='card-body'>
                    <h5 className='card-title'>Helpful Links</h5>
                    <table className='table table-sm'>
                      <tbody>
                        {helpfulLinks && helpfulLinks.length > 0 ? (
                          helpfulLinks.map((link) => {
                            return (
                              <tr className='' key={link.key}>
                                <td>
                                  <a
                                    href={link.link_url}
                                    rel='noreferrer'
                                    target='_blank'>
                                    {link.link_name}
                                  </a>
                                </td>

                                <td> &nbsp;</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className=''>
                            <td>No links found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default DashboardSummary;
