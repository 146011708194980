import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import { auth, db } from '../services/firebase';

import DashboardSummary from '../components/main-sections/DashboardSummary';
import Contacts from '../components/main-sections/Contacts';

import Sidebar from '../components/global/Sidebar';
import SwitchComponents from '../helpers/switchComponents';
import AttendanceRegister from '../components/main-sections/AttendanceRegister';
import BlessingsRegister from '../components/main-sections/BlessingsRegister';
import Budgets from '../components/main-sections/Budgets';
import Events from '../components/main-sections/Events';
import Roles from '../components/main-sections/Roles';
import Help from '../components/main-sections/Help';
import Tasks from '../components/main-sections/Tasks';
import Locations from '../components/main-sections/Locations';
import Links from '../components/main-sections/Links';
import Speakers from '../components/main-sections/Speakers';
import Leaders from '../components/main-sections/Leaders';
import Users from '../components/main-sections/Users';

let unSubSnapshot = {};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoadCompleted: false,
      currentUserDetails: auth().currentUser,
      activeComponent: 'dashboard-summary',
      appData: {},
    };

    this.dashboardAreaRef = React.createRef();
  }

  async addCollectionSnapshot(
    dbRef,
    collection,
    orderByField,
    orderByDirection
  ) {
    /* build any sort and where queries */

    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[collection] = dbRef
          .collection(collection)
          .orderBy(orderByField, orderByDirection)
          .onSnapshot((snapshot) => {
            let setStateData = [];
            let appData = this.state.appData;

            snapshot.forEach((doc) => {
              let snapshotData = [];
              snapshotData = doc.data();
              snapshotData['key'] = doc.id;
              setStateData.push(snapshotData);
            });

            appData[collection] = setStateData;

            this.setState({ appData });
            resolve(true);
          });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async addDocumentSnapshot(dbRef, docId) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[docId] = dbRef.doc(docId).onSnapshot((doc) => {
          const appData = {
            ...this.state.appData,
            [docId]: doc.data(),
          };

          this.setState({ appData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ activeComponent: selectedComponent });
  };

  async componentDidMount() {
    this.setState({ isDataLoadCompleted: false, readError: false });

    try {
      await Promise.all(
        [this.addCollectionSnapshot(db, 'contacts', 'first_name', 'asc')],
        [this.addCollectionSnapshot(db, 'events', 'start_date', 'asc')],
        [this.addCollectionSnapshot(db, 'attendance', 'updated', 'desc')],
        [this.addCollectionSnapshot(db, 'blessings', 'updated', 'desc')],
        [this.addCollectionSnapshot(db, 'expense_claims', 'updated', 'desc')],
        [this.addCollectionSnapshot(db, 'roles', 'role_name', 'asc')],
        [this.addCollectionSnapshot(db, 'locations', 'location_name', 'asc')],
        [this.addCollectionSnapshot(db, 'links', 'link_name', 'asc')],
        [this.addCollectionSnapshot(db, 'tasks', 'task_name', 'asc')],
        [this.addCollectionSnapshot(db, 'users', 'display_name', 'asc')],
        [this.addCollectionSnapshot(db, 'event_tasks', 'due_by', 'desc')],
        [
          this.addCollectionSnapshot(
            db,
            'permission_levels',
            'permission_name',
            'asc'
          ),
        ]
      ).then((result) => {
        /*
        const leadersList = this.state.appData.users
          ? this.state.appData.users.filter((user) =>
              user.permission_levels.includes('9EWrTDPjW5q51nASH4Mi')
            )
          : [];

        console.log(this.state.appData, leadersList);

        const speakersList = this.state.appData.contacts.filter(
          (contact) => contact.is_speaker
        );

        let appData = this.state.appData;
        appData['leaders'] = leadersList;
        appData['speakers'] = speakersList;
        this.setState({ isDataLoadCompleted: true });  */
      });
    } catch (error) {
      this.setState({ isDataLoadCompleted: true, readError: error.message });
      console.log(error);
    }
  }

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  isDataLoaded = () => {
    const appData = this.state.appData;

    if (
      appData.contacts &&
      appData.events &&
      appData.attendance &&
      appData.blessings &&
      appData.expense_claims &&
      appData.locations &&
      appData.roles &&
      appData.links &&
      appData.tasks &&
      appData.event_tasks &&
      appData.users
    ) {
      return true;
    }
    return false;
  };

  render() {
    const isDataLoadCompleted = this.state.isDataLoadCompleted;
    const readError = this.state.readError;
    const currentUserDetails = this.state.currentUserDetails;
    const appData = this.state.appData;

    if (this.isDataLoaded() && !isDataLoadCompleted) {
      const leadersList = this.state.appData.users
        ? this.state.appData.users.filter((user) =>
            user.permission_levels.includes('9EWrTDPjW5q51nASH4Mi')
          )
        : [];

      const speakersList = this.state.appData.contacts.filter(
        (contact) => contact.is_speaker
      );

      appData['leaders'] = leadersList;
      appData['speakers'] = speakersList;
      this.setState({ isDataLoadCompleted: true });
    }

    return (
      <div>
        <Header />

        <div className='d-flex flex-row'>
          {!isDataLoadCompleted ? (
            <div
              className='spinner-border text-success isDataLoadCompleted-indicator'
              role='status'>
              <span className='sr-only'>Loading... </span>
            </div>
          ) : (
            <React.Fragment>
              {!readError ? (
                <>
                  <Sidebar
                    onSwitchComponent={this.handleSwitchComponent}
                    currentUserDetails={this.state.currentUserDetails}
                    usersList={this.state.appData.users}
                    permissionLevels={this.state.appData.permission_levels}
                  />
                  <div className='col text-left'>
                    <h3 className='mt-3'>
                      Welcome{' '}
                      {currentUserDetails ? (
                        <span>
                          <strong>{currentUserDetails.displayName}</strong>
                        </span>
                      ) : (
                        ''
                      )}{' '}
                      to the Mums and Cubs Leaders Admin Portal
                    </h3>
                    <hr />

                    {/* SwitchComponents need more than 1 child element */}
                    <SwitchComponents active={this.state.activeComponent}>
                      <DashboardSummary
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='dashboard-summary'
                      />
                      <Contacts
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='contacts'
                      />
                      <AttendanceRegister
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='attendance-register'
                      />
                      <BlessingsRegister
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='blessings-register'
                      />
                      <Budgets
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='budgets'
                      />
                      <Leaders
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='leaders'
                      />
                      <Events
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='events'
                      />
                      <Roles
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='roles'
                      />
                      <Tasks
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='tasks'
                      />

                      <Locations
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='locations'
                      />

                      <Links
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='links'
                      />

                      <Speakers
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='speakers'
                      />
                      <Users
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name='users'
                      />

                      <Help
                        currentUserDetails={currentUserDetails}
                        name='help'
                      />

                      <div name='blank'>&nbsp;</div>
                    </SwitchComponents>

                    <Footer />
                  </div>
                </>
              ) : (
                { readError }
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard;
