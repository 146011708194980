import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newExpenseClaimDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class ExpenseClaimDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      expenseClaimDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let expenseClaimDetail = this.state.expenseClaimDetail;
    if (
      expenseClaimDetail.expense_date &&
      expenseClaimDetail.expense_date.length > 1
    ) {
      createUpdateDoc(
        'expense_claims',
        expenseClaimDetail.key,
        expenseClaimDetail,
        this.props.isNew
      );
    }

    if (expenseClaimDetail.deleted) {
      recycleDoc('expense_claims', expenseClaimDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ expenseClaimDetail: newExpenseClaimDetail });
    } else {
      this.setState({ expenseClaimDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let expenseClaimDetail = { ...this.state.expenseClaimDetail };

    if (target.type === 'checkbox') {
      expenseClaimDetail[name] = target.checked;
    } else {
      expenseClaimDetail[name] = value;
    }
    this.setState({ expenseClaimDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this blessing entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let expenseClaimDetail = { ...this.state.expenseClaimDetail };
            expenseClaimDetail['deleted'] = true;
            expenseClaimDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ expenseClaimDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleCheck = (selectedItemKey) => {
    let expenseClaimDetail = this.state.expenseClaimDetail;
    let newContactsAttended = [];
    const isChecked = expenseClaimDetail.contacts_involved.includes(
      selectedItemKey
    );

    if (isChecked) {
      newContactsAttended = expenseClaimDetail.contacts_involved.filter(
        (contact) => contact !== selectedItemKey
      );
    } else {
      expenseClaimDetail.contacts_involved.push(selectedItemKey);
      newContactsAttended = expenseClaimDetail.contacts_involved;
    }

    expenseClaimDetail['contacts_involved'] = newContactsAttended;
    this.setState({ expenseClaimDetail });
  };

  render() {
    const expenseClaimDetail = this.state.expenseClaimDetail;
    const contactsList = this.props.contactsList;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group w-25'>
                <label className='form_label' htmlFor='expense_date'>
                  Expense Date
                </label>
                <input
                  className='form-control'
                  placeholder='Expense Date'
                  name='expense_date'
                  type='date'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.expense_date || ''}></input>
              </div>

              <div className='form-group w-25'>
                <label className='form_label' htmlFor='expense_amount'>
                  Expense Amount
                </label>
                <input
                  className='form-control'
                  placeholder='Expense Amount'
                  name='expense_amount'
                  type='number'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.expense_amount || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='expense_description'>
                  Description of the expense
                </label>
                <textarea
                  className='form-control'
                  placeholder='Expense Description'
                  name='expense_description'
                  rows='3'
                  onChange={this.handleItemDetailFormChange}
                  value={
                    expenseClaimDetail.expense_description || ''
                  }></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='contacts_involved'>
                  Contacts
                </label>

                {contactsList.map((item, key) => {
                  let isChecked = false;
                  if (this.state.expenseClaimDetail.contacts_involved) {
                    isChecked = this.state.expenseClaimDetail.contacts_involved.includes(
                      item.key
                    );
                  }

                  return (
                    <div key={key}>
                      <input
                        key={key}
                        name='contact_name'
                        checked={isChecked}
                        type='checkbox'
                        onChange={() => this.handleCheck(item.key)}
                        value={item.key}
                      />
                      &nbsp;
                      <label className='form_label' htmlFor='contact_name'>
                        {item.first_name} {item.last_name}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='intending_to_claim'>
                  Intending to Claim
                </label>
                <select
                  className='form-control'
                  placeholder='Intending to Claim'
                  name='intending_to_claim'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.intending_to_claim || '0'}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='expense_claimed'>
                  Expense Claimed
                </label>
                <select
                  className='form-control'
                  placeholder='Expense Claimed'
                  name='expense_claimed'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.expense_claimed || '0'}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={expenseClaimDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewContact ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Blessing Entry
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
