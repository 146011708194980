import { db, auth } from '../services/firebase';

export function readChats() {
  let abc = [];
  db.ref('chats').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeChats(message) {
  return db.ref('chats').push({
    content: message.content,
    timestamp: message.timestamp,
    uid: message.uid,
  });
}

export function readProfiles() {
  let abc = [];
  db.ref('profiles').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeProfile(profile) {
  return db.ref('profiles').push({
    first_name: profile.first_name,
    uid: profile.uid,
  });
}

export function getFirestoreTimestamp() {
  return db.app.firebase_.firestore.Timestamp.now();
}

export function createUpdateDoc(dbCollRef, docId, dataObj, isNew) {
  const currentFirestoreTimeStamp = getFirestoreTimestamp();

  // extra fields
  dataObj['updated'] = currentFirestoreTimeStamp;
  dataObj['updated_by'] = auth().currentUser.uid;

  if (isNew) {
    dataObj['created'] = currentFirestoreTimeStamp;
    dataObj['created_by'] = auth().currentUser.uid;
    return db.collection(dbCollRef).add(dataObj);
  } else {
    const versionCollRef = db.collection(
      dbCollRef + '/' + docId + '/versions/'
    );
    addVersionHistory(
      db.collection(dbCollRef),
      docId,
      versionCollRef,
      {},
      false
    ).then(() => {
      dataObj['version'] += 1;
      return db.collection(dbCollRef).doc(docId).update(dataObj);
    });
  }
}

export function recycleDoc(dbCollRef, docId) {
  const recycledCollRef = db.collection('/recycled/');
  const originalDocRef = db.collection(dbCollRef);

  //copy doc to be recycled
  copyDoc(originalDocRef, docId, recycledCollRef, {}, false)
    .then(() => {
      // copy versions
      const originalVersionsCollRef = originalDocRef
        .doc(docId)
        .collection('versions');

      const recycledVersionsCollRef = recycledCollRef
        .doc(docId)
        .collection('versions');

      originalVersionsCollRef.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          copyDoc(
            originalVersionsCollRef,
            doc.id,
            recycledVersionsCollRef,
            {},
            false
          ).then(() => {
            originalVersionsCollRef.doc(doc.id).delete();
          });
        });
      });
    })
    .then(() => {
      originalDocRef.doc(docId).delete();
    });
}

export const copyDoc = async (
  collectionFromRef,
  docId,
  collectionToRef,
  addData,
  recursive
) => {
  // document reference
  const docRef = collectionFromRef.doc(docId);

  // copy the document
  const docData = await docRef
    .get()
    .then((doc) => doc.exists && doc.data())
    .catch((error) => {
      console.error('Error reading document', JSON.stringify(error));
    });

  if (docData) {
    // document exists, create the new item
    await collectionToRef
      .doc(docId)
      .set({ ...docData, ...addData })
      .catch((error) => {
        console.error('Error creating document', JSON.stringify(error));
      });

    if (recursive) {
      // subcollections - listCollections does not work in Web App

      const subcollections = await docRef.listCollections();
      for await (const subcollectionRef of subcollections) {
        const subcollectionPath = `${collectionFromRef.path}/${subcollectionRef.id}`;
        console.log(subcollectionPath);
        // get all the documents in the collection
        return await subcollectionRef
          .get()
          .then(async (snapshot) => {
            const docs = snapshot.docs;

            console.log(docs);
            for await (const doc of docs) {
              await copyDoc(
                subcollectionPath,
                doc.id,
                `${collectionToRef.path}/${docId}/${subcollectionRef.id}`,
                true
              );
            }
            return true;
          })
          .catch((error) => {
            console.error(
              'Error reading subcollection',
              subcollectionPath,
              JSON.stringify(error)
            );
          });
      }
    }
    return true;
  }
  return false;
};

export const addVersionHistory = async (
  collectionFromRef,
  docId,
  collectionToRef,
  addData,
  recursive
) => {
  // document reference
  const docRef = collectionFromRef.doc(docId);

  // copy the document
  const docData = await docRef
    .get()
    .then((doc) => doc.exists && doc.data())
    .catch((error) => {
      console.error('Error reading document', JSON.stringify(error));
    });

  if (docData) {
    // document exists, create the new item
    await collectionToRef.add({ ...docData, ...addData }).catch((error) => {
      console.error('Error creating document', JSON.stringify(error));
    });

    if (recursive) {
      // subcollections - listCollections does not work in Web App

      const subcollections = await docRef.listCollections();
      for await (const subcollectionRef of subcollections) {
        const subcollectionPath = `${collectionFromRef.path}/${subcollectionRef.id}`;
        console.log(subcollectionPath);
        // get all the documents in the collection
        return await subcollectionRef
          .get()
          .then(async (snapshot) => {
            const docs = snapshot.docs;

            console.log(docs);
            for await (const doc of docs) {
              await copyDoc(
                subcollectionPath,
                doc.id,
                `${collectionToRef.path}/${docId}/${subcollectionRef.id}`,
                true
              );
            }
            return true;
          })
          .catch((error) => {
            console.error(
              'Error reading subcollection',
              subcollectionPath,
              JSON.stringify(error)
            );
          });
      }
    }
    return true;
  }
  return false;
};
