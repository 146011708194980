import firebase from 'firebase';

var firebaseConfig = {
  apiKey: 'AIzaSyDTRUjMQoPszm4b6gtW15ITKM7YxdJPZDU',
  authDomain: 'mums-and-cubs-admin.firebaseapp.com',
  projectId: 'mums-and-cubs-admin',
  storageBucket: 'mums-and-cubs-admin.appspot.com',
  messagingSenderId: '58069833091',
  appId: '1:58069833091:web:10921aeaee1ab4eec1d2b6',
  measurementId: 'G-DHJN5NSZXT',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth;
export const db = firebase.firestore();
export const cloudFunctions = firebase.functions();
