//Import dayjs for date and time
import dayjs from 'dayjs';
import { db } from '../services/firebase';
import { Type } from 'react-bootstrap-table2-editor';

/* CONTACTS START */

export const newContactDetail = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email_address: '',
  notes: '',
  street_address: '',
  suburb: '',
  state: '',
  postcode: '',
  children_details: '',
  sms_reminders: false,
  on_facebook: false,
  photo_consent_status: 'no',
  photo_consent_date: '',
  church_attendee: false,
  receive_church_information: false,
  partner_name: '',
  care_leader: '',
  status: 'new',
  is_volunteer: false,
  is_speaker: false,
  speaker_topics: '',
  version: 1,
};

export function returnContactsColumnsDef(appData) {
  let contactsTableDef = {
    columns: [
      {
        dataField: 'contact_name',
        text: 'Name',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'key',
        text: 'Contact ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'first_name',
        text: 'First Name',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'last_name',
        text: 'Last Name',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'phone_number',
        text: 'Phone',
        sort: true,
      },
      {
        dataField: 'care_leader',
        text: 'Care Leader',
        sort: true,
        formatter: (cell) => {
          let careLeaderName = 'None';

          if (cell && cell !== 'none') {
            careLeaderName = appData.leaders.filter((leader) => {
              return leader.key === cell;
            });

            careLeaderName = careLeaderName[0].display_name;
          }
          return careLeaderName;
        },
      },
      {
        dataField: 'is_volunteer',
        text: 'Is Volunteer',
        sort: true,
        formatter: (cell) => {
          return cell ? 'Yes' : '';
        },
      },
      {
        dataField: 'is_speaker',
        text: 'Is Speaker',
        sort: true,
        formatter: (cell) => {
          return cell ? 'Yes' : '';
        },
      },
    ],

    defaultSorted: [
      {
        dataField: 'contact_name',
        order: 'asc',
      },
    ],
  };

  return contactsTableDef;
}

/* CONTACTS END */

/* EVENTS START */

export const newEventDetail = {
  title: '',
  start_date: '',
  start_time: '',
  duration: '',
  entry_fee: '',
  max_num_attendees: '',
  location: '',
  notes: '',
  care_leader: '',
  status: 'active',
  version: 1,
};

export function returnEventsColumnsDef(appData) {
  let eventsTableDef = {
    columns: [
      {
        dataField: 'title',
        text: 'Name',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'key',
        text: 'Event ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'start_date',
        text: 'Date',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },
      {
        dataField: 'location',
        text: 'Location',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'care_leader',
        text: 'Care Leader',
        sort: true,
        formatter: (cell) => {
          let careLeaderName = 'None';

          if (cell && cell !== 'none') {
            careLeaderName = appData.leaders.filter((leader) => {
              return leader.key === cell;
            });

            careLeaderName =
              careLeaderName && careLeaderName.length > 0
                ? careLeaderName[0].display_name
                : 'None';
          }
          return careLeaderName;
        },
      },
    ],

    defaultSorted: [
      {
        dataField: 'start_date',
        order: 'asc',
      },
    ],
  };

  return eventsTableDef;
}

/* EVENTS END */

/* ATTENDANCE START */

export const newAttendanceDetail = {
  event_key: '',
  contacts_attended: [],
  new_people: '',
  total_adults: '',
  total_children: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnAttendanceColumnsDef(appData) {
  let attendanceTableDef = {
    columns: [
      {
        dataField: 'event_key',
        text: 'Event',
        sort: true,
        formatter: (cell) => {
          let eventName = 'None';

          if (cell && cell !== 'none') {
            eventName = appData.events.filter((event) => {
              return event.key === cell;
            });

            eventName =
              dayjs(eventName[0].start_date).format('D/M/YYYY') +
              ' ' +
              eventName[0].title;
          }
          return eventName;
        },
      },
      {
        dataField: 'key',
        text: 'Attedance ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'total_adults',
        text: 'Total Adults',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'total_children',
        text: 'Total Children',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'start_date',
        order: 'asc',
      },
    ],
  };

  return attendanceTableDef;
}

/* ATTENDANCE END */

/* BLESSINGS START */

export const newBlessingDetail = {
  contacts_involved: [],
  expense_description: '',
  blessing_date: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnBlessingsColumnsDef(appData) {
  let blessingTableDef = {
    columns: [
      {
        dataField: 'blessing_date',
        text: 'Date',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },
      {
        dataField: 'key',
        text: 'Blessing ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'expense_description',
        text: 'Blessing Description',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'blessing_date',
        order: 'desc',
      },
    ],
  };

  return blessingTableDef;
}

/* BLESSINGS END */

/* EXPENSE CLAIMS START */

export const newExpenseClaimDetail = {
  contacts_involved: [],
  expense_amount: '',
  expense_description: '',
  expense_date: '',
  linked_event: '',
  intending_to_claim: false,
  expense_claimed: false,
  notes: '',
  status: 'new',
  version: 1,
};

export function returnExpenseClaimsColumnsDef(appData) {
  let expenseClaimsTableDef = {
    columns: [
      {
        dataField: 'expense_date',
        text: 'Date',
        sort: true,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
      },
      {
        dataField: 'key',
        text: 'Expense Claim ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'linked_event',
        text: 'Event',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'expense_description',
        text: 'Expense Description',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'expense_amount',
        text: 'Amount',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'intending_to_claim',
        text: 'Intending',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          return cell ? 'Yes' : 'No';
        },
      },
      {
        dataField: 'expense_claimed',
        text: 'Claimed',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          return cell ? 'Yes' : 'No';
        },
      },
    ],

    defaultSorted: [
      {
        dataField: 'expense_date',
        order: 'desc',
      },
    ],
  };

  return expenseClaimsTableDef;
}

/* EXPENSE CLAIMS END */

/* LEADERS START */

export function returnLeadersColumnsDef(appData) {
  let contactsTableDef = {
    columns: [
      {
        dataField: 'contact_name',
        text: 'Name',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'key',
        text: 'Contact ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'mobile_phone',
        text: 'Phone',
        sort: true,
      },
    ],

    defaultSorted: [
      {
        dataField: 'contact_name',
        order: 'asc',
      },
    ],
  };

  return contactsTableDef;
}

/* LEADERS END */

/* ROLES START */

export const newRoleDetail = {
  contacts_involved: [],
  role_name: '',
  role_description: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnRolesColumnsDef(appData) {
  let locationsTableRef = {
    columns: [
      {
        dataField: 'role_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Roles ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'role_description',
        text: 'Role Description',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'role_name',
        order: 'asc',
      },
    ],
  };

  return locationsTableRef;
}

/* ROLES END */

/* LOCATIONS START */

export const newLocationDetail = {
  location_name: '',
  location_description: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnLocationsColumnsDef(appData) {
  let locationsTableRef = {
    columns: [
      {
        dataField: 'location_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Location ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'location_description',
        text: 'Location Description',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'location_name',
        order: 'asc',
      },
    ],
  };

  return locationsTableRef;
}

/* LOCATIONS END */

/* LINKS START */

export const newLinkDetail = {
  link_name: '',
  link_url: '',
  link_description: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnLinksColumnsDef(appData) {
  let linksTableRef = {
    columns: [
      {
        dataField: 'link_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'link_url',
        text: 'URL',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Location ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'link_description',
        text: 'Location Description',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'link_name',
        order: 'asc',
      },
    ],
  };

  return linksTableRef;
}

/* LINKS END */

/* SPEAKERS START */
/* based on contact dataObject */

export function returnSpeakersColumnsDef(appData) {
  let speakersTableRef = {
    columns: [
      {
        dataField: 'first_name',
        text: 'First Name',
        sort: true,
      },
      {
        dataField: 'last_name',
        text: 'Last Name',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Contact ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'phone_number',
        text: 'Phone',
        sort: true,
      },
    ],

    defaultSorted: [
      {
        dataField: 'first_name',
        order: 'asc',
      },
    ],
  };

  return speakersTableRef;
}

/* SPEAKERS END */

/* TASKS START */

export const newTaskDetail = {
  task_name: '',
  task_description: '',
  notes: '',
  status: 'new',
  version: 1,
};

export function returnTasksColumnsDef(appData) {
  let tasksTableRef = {
    columns: [
      {
        dataField: 'task_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Task ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'task_description',
        text: 'Task Description',
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: 'link_name',
        order: 'asc',
      },
    ],
  };

  return tasksTableRef;
}

/* TASKS END */

/* USERS START */

export const newUserDetail = {
  display_name: '',
  mobile_phone: '',
  email_address: '',
  notes: '',
  access_allowed: false,
  permission_levels: [],
  version: 1,
};

export function returnUsersColumnsDef(appData) {
  let usersTableDef = {
    columns: [
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'key',
        text: 'User ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'mobile_phone',
        text: 'Phone',
        sort: true,
      },
      {
        dataField: 'email_address',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'access_allowed',
        text: 'Access',
        sort: true,
        formatter: (cell) => {
          return cell ? 'Yes' : '';
        },
      },
    ],

    defaultSorted: [
      {
        dataField: 'display_name',
        order: 'asc',
      },
    ],
  };

  return usersTableDef;
}

/* USERS END */

/* EVENT TASKS START */

export const newEventTaskDetail = {
  event_id: '',
  app_task_id: '',
  task_name: '',
  assigned_leader_id: '',
  due_by: '',
  completed: false,
  notes: '',
  status: 'new',
  version: 1,
};

export function returnEventTasksColumnsDef(appData, leadersList) {
  console.log(leadersList);
  leadersList = leadersList.map((item, key) => {
    return {
      value: item.key,
      label: item.display_name,
    };
  });
  leadersList.push({ value: '', label: 'All' });

  let tasksTableRef = {
    columns: [
      {
        dataField: 'task_name',
        text: 'Task',
        sort: true,
      },
      {
        dataField: 'key',
        text: 'Event Task ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'assigned_leader_id',
        text: 'Who',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          const leaderName = leadersList.filter((leader) => {
            return leader.value === cell;
          });

          return cell && leaderName && leaderName.length > 0 ? (
            <strong>{leaderName[0].label}</strong>
          ) : (
            'All'
          );
        },
        editor: {
          type: Type.SELECT,
          options: leadersList,
        },
      },
      {
        dataField: 'due_by',
        text: 'Due',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          return cell ? dayjs(cell).format('D/M/YYYY') : 'No Date';
        },
        editor: {
          type: Type.DATE,
        },
      },
      {
        dataField: 'completed',
        text: 'Done',
        sort: true,
        hidden: false,
        formatter: (cell) => {
          return cell ? 'Yes' : '';
        },
        editor: {
          type: Type.CHECKBOX,
          value: 'Y:N',
        },
      },
      {
        dataField: 'notes',
        text: 'Note',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'actions',
        text: '',
        sort: true,
        isDummyField: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleRemoveRow(row);
          },
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div key={rowIndex} className='btn btn-sml btn-outline-dark'>
              X
            </div>
          );
        },
      },
    ],

    defaultSorted: [
      {
        dataField: 'completed',
        order: 'desc',
      },
    ],
  };

  function handleRemoveRow(row) {
    db.collection('event_tasks').doc(row.key).delete();
  }

  return tasksTableRef;
}

/* EVENT TASKS END */
