import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import SlidingPane from 'react-sliding-pane';
import { returnRolesColumnsDef } from '../../helpers/dataObjects';
import RoleDetailForm from '../forms/RoleDetailForm';

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      isSlidingPaneOpen: false,
      slidingPaneTitle: 'Role Details',
    };
  }

  handleAddNewItemButtonClick = () => {
    this.setState({
      isNew: true,
      isSlidingPaneOpen: true,
    });
  };

  handleItemRowClicked = (row) => {
    this.setState({
      selectedItemDetail: row,
      isNew: false,
      isSlidingPaneOpen: true,
    });
  };

  async componentDidMount() {}

  handleItemSaveDeleteButtonClick = (value) => {
    this.setState({ isSlidingPaneOpen: false });
  };

  async componentWillUnmount() {}

  render() {
    const appData = this.props.appData;
    let filteredRoles = appData['roles'] ? appData['roles'] : [];

    const rolesTableDef = returnRolesColumnsDef(appData);
    const columns = rolesTableDef.columns;
    const defaultSorted = rolesTableDef.defaultSorted;
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleItemRowClicked(row);
        /* this.setState({
          selectedItemDetail: row,
          isNewContact: false,
          active: true,
        });  */
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <>
        <div>Roles page</div>
        <div
          onClick={() => this.handleAddNewItemButtonClick()}
          className='btn btn-outline-dark ml-auto mb-2'>
          <span>Add New Role</span>
        </div>
        <BootstrapTable
          noDataIndication='There are no contacts to display here at the moment'
          classes='auto-table-layout'
          keyField='key'
          data={filteredRoles}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='sliding-pane'
          overlayClassName='pane-details-sliding-pane'
          isOpen={this.state.isSlidingPaneOpen}
          title={this.state.slidingPaneTitle}
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isSlidingPaneOpen: false });
          }}>
          <RoleDetailForm
            isNew={this.state.isNew}
            selectedItemDetail={this.state.selectedItemDetail}
            currentUserDetails={this.props.currentUserDetails}
            leadersList={this.props.appData.leaders}
            onItemSaveDeleteButtonClicked={this.handleItemSaveDeleteButtonClick}
          />
        </SlidingPane>
      </>
    );
  }
}
export default Roles;
