import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { db, auth } from './services/firebase';
import { getFirestoreTimestamp } from './helpers/db';
import './styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import ProfileDetails from './pages/ProfileDetails';
import { newUserDetail } from './helpers/dataObjects';

const firestoreTimeStamp = getFirestoreTimestamp();

async function userDetails(user) {
  let userDetail = {};
  await db
    .collection('users')
    .doc(user.uid)
    .get()
    .then((snapshot) => {
      if (snapshot && snapshot.exists) {
        userDetail = snapshot.data();
      } else {
        const newUser = {
          ...newUserDetail,
          key: user.uid,
          display_name: user.displayName ? user.displayName : '',
          email_address: user.email ? user.email : '',
          mobile_phone: user.phoneNumber ? user.phoneNumber : '',
          access_allowed: false,
          created: firestoreTimeStamp,
        };

        db.collection('users').doc(user.uid).set(newUser);
        userDetail = newUser;
      }
    });

  return userDetail;
}

function PrivateRoute({
  component: Component,
  authenticated,
  currentUserDetails,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} currentUserDetails={currentUserDetails} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  let locationPath = useLocation();

  let locationPathname = '/dashboard';
  if (locationPath.state) {
    locationPathname = locationPath.state.from.pathname;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={locationPathname} />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
      loadingModule: 'App',
      currentUserDetails: {},
      dashboardData: {},
    };
  }

  async componentDidMount() {
    const pathArray = window.location.pathname.split('/');

    auth().onAuthStateChanged((user) => {
      if (user) {
        Promise.all([userDetails(user)]).then((result) => {
          const currentUserDetails = result[0];

          if (
            !currentUserDetails.access_allowed &&
            pathArray[1] !== 'profile'
          ) {
            window.location = '/profile';
          }

          this.setState({
            authenticated: true,
            loading: false,
            currentUserDetails,
          });
        });
      } else {
        this.setState({ authenticated: false, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <React.Fragment>
        <div
          style={{ textAlign: 'center', marginLeft: '30px' }}
          className='loading-text'>
          Life is change. Growth is optional. Choose wisely. <br />
          <em>Karen Clarke</em>
        </div>
        <div
          className='spinner-border text-success loading-indicator'
          role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </React.Fragment>
    ) : (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />

          <PrivateRoute
            path='/profile'
            authenticated={this.state.authenticated}
            component={ProfileDetails}
            currentUserDetails={this.state.currentUserDetails}
          />

          <PrivateRoute
            path='/dashboard'
            authenticated={this.state.authenticated}
            component={Dashboard}
            currentUserDetails={this.state.currentUserDetails}
          />

          <PublicRoute
            path='/login'
            authenticated={this.state.authenticated}
            component={Login}
          />

          <PublicRoute
            path='/signup'
            authenticated={this.state.authenticated}
            component={Signup}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
