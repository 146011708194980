import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newLocationDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class LocationDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      locationDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let locationDetail = this.state.locationDetail;
    if (
      locationDetail.location_name &&
      locationDetail.location_name.length > 1
    ) {
      createUpdateDoc(
        'locations',
        locationDetail.key,
        locationDetail,
        this.props.isNew
      );
    }

    if (locationDetail.deleted) {
      recycleDoc('locations', locationDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ locationDetail: newLocationDetail });
    } else {
      this.setState({ locationDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let locationDetail = { ...this.state.locationDetail };

    if (target.type === 'checkbox') {
      locationDetail[name] = target.checked;
    } else {
      locationDetail[name] = value;
    }
    this.setState({ locationDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this location entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let locationDetail = { ...this.state.locationDetail };
            locationDetail['deleted'] = true;
            locationDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ locationDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const locationDetail = this.state.locationDetail;
    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group w-50'>
                <label className='form_label' htmlFor='location_name'>
                  Location Name
                </label>
                <input
                  className='form-control'
                  placeholder='Location Name'
                  name='location_name'
                  type='text'
                  onChange={this.handleItemDetailFormChange}
                  value={locationDetail.location_name || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='location_description'>
                  Location Description
                </label>
                <textarea
                  className='form-control'
                  placeholder='Location Description'
                  name='location_description'
                  rows='3'
                  onChange={this.handleItemDetailFormChange}
                  value={locationDetail.location_description || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={locationDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={locationDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewContact ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Location
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
