import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUsers,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faClipboardCheck,
  faHandsHelping,
  faMoneyBillWave,
  faUserTag,
  faCalendarCheck,
  faQuestion,
  faStar,
  faCog,
  faMap,
  faLink,
  faAddressBook,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SubMenu,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  handleResize = (e) => {
    let sideBarIsCollapsed = this.state.sideBarIsCollapsed;
    sideBarIsCollapsed = window.innerWidth > 768 ? false : true;
    this.setState({ windowWidth: window.innerWidth, sideBarIsCollapsed });
  };

  handleSidebarHeaderClick = () => {
    const sideBarIsCollapsed = !this.state.sideBarIsCollapsed;
    this.setState({ sideBarIsCollapsed });
  };

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ selectedOption: '' });

    this.props.onSwitchComponent(selectedComponent);

    this.setState({ selectedOption: selectedComponent });
  };

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const selectedOption = this.state.selectedOption;
    // const permisssionLevels = this.props.permisssionLevels;

    let isCareLeader = false;
    let isSuperAdmin = false;
    let isSettingsAdmin = false;
    let isUsersAdmin = false;

    if (this.props.usersList && this.props.currentUserDetails) {
      const currentUserExpandedDetails = this.props.usersList.filter(
        (user) => user.key === this.props.currentUserDetails.uid
      );

      isCareLeader = currentUserExpandedDetails[0].permission_levels.includes(
        '9EWrTDPjW5q51nASH4Mi'
      );
      isSuperAdmin = currentUserExpandedDetails[0].permission_levels.includes(
        'HmDFTK6yQJ60Hmyi0VeN'
      );
      isSettingsAdmin =
        currentUserExpandedDetails[0].permission_levels.includes(
          'LsPPbdcizgKfcyfOA31a'
        );
      isUsersAdmin = currentUserExpandedDetails[0].permission_levels.includes(
        'XT7WrF2cAF1xjeGMopeO'
      );
    }

    return (
      <div
        className='ml-auto'
        style={{ zIndex: '0', minHeight: 'calc(100vh - 120px)' }}>
        <ProSidebar className='' collapsed={this.state.sideBarIsCollapsed}>
          <SidebarHeader
            className='text-center sidebar-header-arrow'
            onClick={this.handleSidebarHeaderClick}>
            {this.state.sideBarIsCollapsed ? (
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            ) : (
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            )}
          </SidebarHeader>
          <SidebarContent className=''>
            <Menu popperArrow='true' iconShape='circle'>
              <MenuItem
                className={
                  selectedOption === 'dashboard-summary' ? 'selectedOption' : ''
                }
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => this.handleSwitchComponent('dashboard-summary')}>
                Dashboard
              </MenuItem>

              {isCareLeader || isSuperAdmin ? (
                <>
                  <MenuItem
                    className={
                      selectedOption === 'contacts' ? 'selectedOption' : ''
                    }
                    icon={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={() => this.handleSwitchComponent('contacts')}>
                    Contacts
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === 'events' ? 'selectedOption' : ''
                    }
                    icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                    onClick={() => this.handleSwitchComponent('events')}>
                    Events
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === 'attendance-register'
                        ? 'selectedOption'
                        : ''
                    }
                    icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                    onClick={() =>
                      this.handleSwitchComponent('attendance-register')
                    }>
                    Attendance Register
                  </MenuItem>
                  <MenuItem
                    className={
                      selectedOption === 'blessings-register'
                        ? 'selectedOption'
                        : ''
                    }
                    icon={<FontAwesomeIcon icon={faHandsHelping} />}
                    onClick={() =>
                      this.handleSwitchComponent('blessings-register')
                    }>
                    Blessings Register
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === 'budgets' ? 'selectedOption' : ''
                    }
                    icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                    onClick={() => this.handleSwitchComponent('budgets')}>
                    Expense Claim
                  </MenuItem>
                  <MenuItem
                    className={
                      selectedOption === 'leaders' ? 'selectedOption' : ''
                    }
                    icon={<FontAwesomeIcon icon={faStar} />}
                    onClick={() => this.handleSwitchComponent('leaders')}>
                    Leaders
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === 'help' ? 'selectedOption' : ''
                    }
                    icon={<FontAwesomeIcon icon={faQuestion} />}
                    onClick={() => this.handleSwitchComponent('help')}>
                    Help
                  </MenuItem>
                </>
              ) : (
                ''
              )}

              {isSettingsAdmin || isSuperAdmin ? (
                <>
                  <SubMenu
                    title='Settings'
                    icon={<FontAwesomeIcon icon={faCog} />}>
                    <MenuItem
                      className={
                        selectedOption === 'roles' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faUserTag} />}
                      onClick={() => this.handleSwitchComponent('roles')}>
                      Roles
                    </MenuItem>

                    <MenuItem
                      className={
                        selectedOption === 'locations' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faMap} />}
                      onClick={() => this.handleSwitchComponent('locations')}>
                      Locations
                    </MenuItem>

                    <MenuItem
                      className={
                        selectedOption === 'links' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faLink} />}
                      onClick={() => this.handleSwitchComponent('links')}>
                      Links
                    </MenuItem>

                    <MenuItem
                      className={
                        selectedOption === 'speakers' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faUsers} />}
                      onClick={() => this.handleSwitchComponent('speakers')}>
                      Speakers
                    </MenuItem>

                    <MenuItem
                      className={
                        selectedOption === 'tasks' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faTasks} />}
                      onClick={() => this.handleSwitchComponent('tasks')}>
                      Tasks
                    </MenuItem>
                  </SubMenu>
                </>
              ) : (
                ''
              )}

              {isUsersAdmin || isSuperAdmin ? (
                <>
                  <SubMenu
                    title='Users'
                    icon={<FontAwesomeIcon icon={faUserTag} />}>
                    <MenuItem
                      className={
                        selectedOption === 'users' ? 'selectedOption' : ''
                      }
                      icon={<FontAwesomeIcon icon={faUserTag} />}
                      onClick={() => this.handleSwitchComponent('users')}>
                      Users
                    </MenuItem>
                  </SubMenu>
                </>
              ) : (
                ''
              )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    );
  }
}

export default Sidebar;
