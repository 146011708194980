import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newEventDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';
import EventTasks from '../sub-sections/EventsTasks';

export default class EventDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      eventDetail: {},
      cancelAction: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let eventDetail = this.state.eventDetail;

    if (eventDetail.title.length > 1 || !this.state.cancelAction) {
      createUpdateDoc('events', eventDetail.key, eventDetail, this.props.isNew);
    }

    if (eventDetail.deleted) {
      recycleDoc('events', eventDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ eventDetail: newEventDetail });
    } else {
      this.setState({ eventDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let eventDetail = { ...this.state.eventDetail };

    if (target.type === 'checkbox') {
      eventDetail[name] = target.checked;
    } else {
      eventDetail[name] = value;
    }
    this.setState({ eventDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemCancelButton = () => {
    this.setState({ cancelAction: true });
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this event?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let eventDetail = { ...this.state.eventDetail };
            eventDetail['deleted'] = true;
            eventDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ eventDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const eventDetail = this.state.eventDetail;
    const leadersList = this.props.leadersList;

    const tasksList = this.props.appData.tasks;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <React.Fragment>
              <div className='d-flex'>
                <div className='d-flex small w-50'>
                  Edit details below. Close out of this panel when finished as
                  your changes will be saved automatically.
                </div>
              </div>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <div className='form-group'>
                  <label className='form_label' htmlFor='title'>
                    Event Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Event Name'
                    name='title'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={eventDetail.title || ''}></input>
                </div>

                <div className='d-flex'>
                  <div className='form-group w-15'>
                    <label className='form_label' htmlFor='start_date'>
                      Event Date
                    </label>
                    <input
                      className='form-control'
                      placeholder='Event Date'
                      name='start_date'
                      type='date'
                      onChange={this.handleItemDetailFormChange}
                      value={eventDetail.start_date || ''}></input>
                  </div>

                  <div className='form-group w-15'>
                    <label className='form_label' htmlFor='start_time'>
                      Event Start Time
                    </label>
                    <input
                      className='form-control'
                      placeholder='Start Time'
                      name='start_time'
                      type='time'
                      onChange={this.handleItemDetailFormChange}
                      value={eventDetail.start_time || ''}></input>
                  </div>

                  <div className='form-group w-15'>
                    <label className='form_label' htmlFor='duration'>
                      Duration
                    </label>
                    <input
                      className='form-control'
                      placeholder='Duration'
                      name='duration'
                      type='text'
                      onChange={this.handleItemDetailFormChange}
                      value={eventDetail.duration || ''}></input>
                  </div>
                  <div className='form-group w-15'>
                    <label className='form_label' htmlFor='entry_fee'>
                      Entry Fee
                    </label>
                    <input
                      className='form-control'
                      placeholder='Entry Fee'
                      name='entry_fee'
                      type='text'
                      onChange={this.handleItemDetailFormChange}
                      value={eventDetail.entry_fee || ''}></input>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='location'>
                    Location Details (inc Address)
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Location Details'
                    name='location'
                    rows='3'
                    onChange={this.handleItemDetailFormChange}
                    value={eventDetail.location || ''}></textarea>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleItemDetailFormChange}
                    value={eventDetail.notes || ''}></textarea>
                </div>

                <div className='form-group w-50'>
                  {' '}
                  <label className='form_label' htmlFor='care_leader'>
                    Event Leader
                  </label>
                  <select
                    className='form-control'
                    name='care_leader'
                    onChange={this.handleItemDetailFormChange}
                    value={
                      eventDetail.care_leader ? eventDetail.care_leader : 'none'
                    }>
                    <option value='none'>No Care Leader Assigned</option>
                    {leadersList.map((item, key) => {
                      return (
                        <option key={key} value={item.key}>
                          {item.display_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='form-group w-25'>
                  {' '}
                  <label className='form_label' htmlFor='status'>
                    Status
                  </label>
                  <select
                    className='form-control'
                    placeholder='Status'
                    name='status'
                    onChange={this.handleItemDetailFormChange}
                    value={eventDetail.status || 'active'}>
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                    <option value='archived'>Archived</option>
                  </select>
                </div>

                <div className='form-group'>
                  {this.state.error ? (
                    <p className='text-danger'>{this.state.error}</p>
                  ) : null}
                </div>
              </form>
              <div
                onClick={this.handleItemSaveButton}
                className='small btn btn-success mx-5'>
                Save Changes
              </div>

              <div
                onClick={this.handleItemCancelButton}
                className='small btn btn-danger mx-5'>
                Cancel
              </div>

              {!this.props.isNew ? (
                <div
                  onClick={this.handleItemDeleteButton}
                  className='small btn btn-danger'>
                  Delete this Event
                </div>
              ) : (
                ''
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
