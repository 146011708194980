import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newRoleDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class RoleDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      roleDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let roleDetail = this.state.roleDetail;
    if (roleDetail.role_name && roleDetail.role_name.length > 1) {
      createUpdateDoc('roles', roleDetail.key, roleDetail, this.props.isNew);
    }

    if (roleDetail.deleted) {
      recycleDoc('roles', roleDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ roleDetail: newRoleDetail });
    } else {
      this.setState({ roleDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let roleDetail = { ...this.state.roleDetail };

    if (target.type === 'checkbox') {
      roleDetail[name] = target.checked;
    } else {
      roleDetail[name] = value;
    }
    this.setState({ roleDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this role entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let roleDetail = { ...this.state.roleDetail };
            roleDetail['deleted'] = true;
            roleDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ roleDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleCheck = (selectedItemKey) => {
    let roleDetail = this.state.roleDetail;
    let newContactsAttended = [];
    const isChecked = roleDetail.contacts_involved.includes(selectedItemKey);

    if (isChecked) {
      newContactsAttended = roleDetail.contacts_involved.filter(
        (contact) => contact !== selectedItemKey
      );
    } else {
      roleDetail.contacts_involved.push(selectedItemKey);
      newContactsAttended = roleDetail.contacts_involved;
    }

    roleDetail['contacts_involved'] = newContactsAttended;
    this.setState({ roleDetail });
  };

  render() {
    const roleDetail = this.state.roleDetail;
    const contactsList = this.props.leadersList;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group w-50'>
                <label className='form_label' htmlFor='role_name'>
                  Role Name
                </label>
                <input
                  className='form-control'
                  placeholder='Role Name'
                  name='role_name'
                  type='text'
                  onChange={this.handleItemDetailFormChange}
                  value={roleDetail.role_name || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='role_description'>
                  Role Description
                </label>
                <textarea
                  className='form-control'
                  placeholder='Role Description'
                  name='role_description'
                  rows='3'
                  onChange={this.handleItemDetailFormChange}
                  value={roleDetail.role_description || ''}></textarea>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='contacts_involved'>
                  Leaders
                </label>

                {contactsList.map((item, key) => {
                  let isChecked = false;
                  if (this.state.roleDetail.contacts_involved) {
                    isChecked = this.state.roleDetail.contacts_involved.includes(
                      item.key
                    );
                  }

                  return (
                    <div key={key}>
                      <input
                        key={key}
                        name='contact_name'
                        checked={isChecked}
                        type='checkbox'
                        onChange={() => this.handleCheck(item.key)}
                        value={item.key}
                      />
                      &nbsp;
                      <label className='form_label' htmlFor='contact_name'>
                        {item.first_name} {item.last_name}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={roleDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={roleDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewContact ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Role
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
